/* Toplevel ---------------------------------------------------------------- */

html { overflow-y: scroll; background-color: #040408; }
html, body { overflow-x: hidden; color: #fff; font-family: Arial, sans-serif; }
body { margin: 1ex; }

.md-rendered { max-width: 50em; margin: 1ex auto; }

a { color: #ff8; text-decoration: none; padding: 0 0.125em; }

pre.md { display: none; }

:focus-visible { outline: #fff8 solid 1.5px; border-radius: 4px; }

/* Markdown elements ------------------------------------------------------- */

pre, code, kbd, textarea, tt {
  font-size: 1em; font-family: Cascadia Code, Cascadia, Consolas, monospace, monospace;
}
pre, code, kbd { background-color: #4448; border-radius: 0.5ex; }
pre code { background-color: transparent; padding: 0; }
code, kbd { padding: 0 0.25ex; border-radius: 0.25ex; }
kbd { border: #666 solid 1px; }
pre {
  margin-block: 1ex; margin-inline: 1ex auto; padding: 0.5ex 1ex; width: fit-content;
  border: #444 solid 2px; border-radius: 1ex 0 0 0;
}

h1, h2, h3, h4, h5, :is(div, blockquote, details):has(h1, h2, h3, h4, h5) {
  margin-block: 3ex 2ex; margin-inline: 0 auto;
}
:is(h1, h2, h3, h4, h5, :is(div, blockquote, details):has(h1, h2, h3, h4, h5)):first-child {
  margin-block-start: 1.5ex;
}
:is(h1, h2, h3, h4, h5, :is(div, blockquote, details):has(h1, h2, h3, h4, h5)):last-child {
  margin-block-end: 1ex;
}

p, li, blockquote, ul, ol, dl, details, summary { margin-block: 1.5ex; margin-inline: 0; }
blockquote, li, dd {
  & p { margin-block: 0.5ex; }
  & :is(p, blockquote, pre, ul, ol, li, dl, dd, dt, div, details, summary):first-child { margin-block-start: 0; }
  & :is(p, blockquote, pre, ul, ol, li, dl, dd, dt, div, details, summary):last-child { margin-block-end: 0; }
}

:is(blockquote, li, dd) :is(pre, blockquote) { margin-inline-start: 0; }

blockquote {
  font-size: 85%; font-style: italic; & em { font-style: normal; }
  margin-inline-start: 1em; padding: 0.5ex;
  border-inline-start: 4px solid #333; border-radius: 1ex;
  & blockquote { margin-inline-start: 0.5ex; }
}

table { margin: 1.5ex auto; }
td, th { padding: 0.25ex 0.5ex; }
th { background-color: #333; }
td { background-color: #222; }

mark {
  background-color: #ff48; color: #fff; padding: 0 2px; border-radius: 2px;
}

.footnotes { font-size: 82.5%; color: #ccc; }
hr.footnotes-sep { margin-block: 2em 1em; }

.error {
  background-color: #f444; color: #ff8; font-weight: bold; border-radius: 5px;
  padding: 0.5ex 1ex; width: 75%; margin-inline: auto;
}

details > summary {
  cursor: pointer; list-style: none; color: #ffb; transition: color 0.3s;
}
details > summary > *, details > summary > :is(blockquote, ol, ul, dl) > * {
  display: inline;
}
details[open] > summary { color: #fff; }
details > summary::before {
  content: "⯈"; color: #ffa8; font-size: 125%; margin-right: 0.5ex;
  font-style: normal; display: inline-block;
  transition: color 0.3s, transform 0.3s;
}
details[open] > summary::before {   color: #ffa; transform: rotate(90deg); }

/* Lists and labeled items ------------------------------------------------- */

ul { padding-inline-start: 1em; margin-inline-start: 0.5ex; }
ol { padding-inline-start: 1.5em; }
dl, dt, dd { padding-inline-start: 0em; }
dl dd      { margin-inline-start: 1em; }

/* keep margins in case of having paragraphs */
:is(ul, ol) li:not(:has(p)) { margin-block: 0.2ex 0.4ex; min-height: 1lh; }
:is(ul, ol) li[type]:not(:has(p)) { margin-block: 0.5ex 0.75ex; }
:is(ul, ol, dl) :is(ul, ol, dl):not(:has(p)) { margin-block: 0.5ex; }
:is(dl) :is(dd, dt):not(:has(p)) { margin-block: 0.5ex 0.75ex; }

dl dt { font-weight: bold; font-style: italic; }

ul li[type] {
  list-style-type: none;  padding-inline: 1ex; margin-left: 0;
  border-radius: 4px; width: fit-content;
  background-color: oklch(from var(--itemColor, transparent)
                          l 25% h / calc(alpha * 0.4));
  &:has(label:only-child) { background-color: transparent; } /* only label => no bg */
  & label {
    display: inline-block; direction: rtl;
    width: 0; margin-left: -1ex; float: left;
  }
  & label > tt {
    display: inline-block; direction: ltr;
    width: fit-content; line-height: 75%; padding: 0.2em;
    font-weight: bold;
    white-space: nowrap;
    border-radius: 4px;
    background-color: oklch(from var(--bullColor, var(--itemColor, oklch(50% 40% 300)))
                            l 40% h / calc(alpha * 0.75));
  }
  & > p { margin-block: 0; }
}
/* https://oklch.com/ */
ul li {
  &[type="**"]                             { --itemColor: oklch(50% 40% 300); }
  &[type="+"],  &[type="*+"], &[type="+*"] { --bullColor: oklch(50% 40% 120); }
  &[type="++"], &[type="v"]                { --itemColor: oklch(50% 40% 120); }
  &[type="-"],  &[type="*-"], &[type="-*"] { --bullColor: oklch(50% 40%   0); }
  &[type="--"], &[type="x"]                { --itemColor: oklch(50% 40%   0); }
  &[type="?"]                              { --itemColor: oklch(50% 40% 240); }
  &[type="!"]                              { --itemColor: oklch(50% 40%  60); }
  &[type="??"]                             { --itemColor: oklch(75% 40% 240); }
  &[type="*?"], &[type="?*"]               { --bullColor: oklch(75% 40% 240); }
  &[type="!!"]                             { --itemColor: oklch(75% 40%  60); }
  &[type="*!"], &[type="!*"]               { --bullColor: oklch(75% 40%  60); }
}

/* Widgets ----------------------------------------------------------------- */

button { font-size: 100%; font-weight: bold; }

#widgets {
  position: fixed; top: 0; right: 0; text-align: right;
  display: flex; flex-direction: row; margin: 0 0.5ex;
  & button {
    padding: 0.5ex; margin: 1ex 0.5ex;
    color: #aaa; background-color: #444; border: 2px #666 solid; font-size: 75%;
    text-shadow: 1px 1px 1px #000; border-radius: 5px;
    transition: all ease-in-out 0.2s;
    &:hover, &:active {
      background-color: #666; border-color: #888; text-shadow: 2px 2px 2px #000;
      padding: 0.75ex 0.75ex; margin: 0.75ex 0.25ex;
    }
    &.on { color: #8c8; background-color: #446; filter: brightness(1.5); }
  }
}

/* Useful generic styles --------------------------------------------------- */

.big { font-size: 120%; }
.small { font-size: 80%; }
